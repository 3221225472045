/* eslint-disable import/extensions */
import {
  appraisalDashboardModule,
  webCommonService,
  webDashboardService
} from "./mfe-remotes-config/remoteConfig.js";

window[webCommonService.urlGlobalVariable] = webCommonService.url;
window[appraisalDashboardModule.urlGlobalVariable] =
  appraisalDashboardModule.url;
window[webDashboardService.urlGlobalVariable] = webDashboardService.url;

import("./bootstrap.jsx");
